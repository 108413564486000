<template>
  <div>
    <el-row :gutter="10"
            style="padding-bottom:10px">
      <el-col :span="6">
        <el-button type="primary"
                   @click="add">新增</el-button>
      </el-col>
    </el-row>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />
  </div>
</template>

<script>
import Form from './components/form.vue'
export default {
  data () {
    return {
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      pageName: 'video_resource_study_stage',
      tableColumns: [
        { prop: 'stage_id', align: 'center', label: '编号', width: '100', showToopic: false },
        { prop: 'stage_name', align: 'center', label: '学习阶段', width: '200', showToopic: true },
        { prop: 'grade_name', align: 'center', label: '年级', width: '300', showToopic: true },
        { prop: 'desc', align: 'center', label: '阶段描述', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '200', type: 'img', btns: [
            { content: '修改', type: 'edit', event: 'edit' },
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ]
    }
  },
  components: {
    Form
  },
  mounted () {
    this.setTablesColums()
    this.initData()
  },
  methods: {
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData () {
      this.tableLoading = true
      this.$http({
        url: '/api/v1/study/lst',
        method: 'get',
      }).then(res => {
        this.tableData = Object.values(res.data)
      })
    },
    delRow (row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/study/del',
          method: 'post',
          data: {
            stage_id: row.stage_id
          }
        })
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        setTimeout(() => {
          this.initData()
        }, 500);
      })
    },
    edit (row) {
      let form = JSON.parse(JSON.stringify(row))
      form.grade_id = form.grade_id ? form.grade_id.split(',').map(item => Number(item)) : []
      this.$refs.FormRef.text = '修改'
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    add () {
      let form = {
        stage_name: '',
        grade_id: [],
        desc: '',
      }
      this.$refs.FormRef.text = '新增'
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true

    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}
</style>
